import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';

const createAgentURL = cs.BaseURL + '/agent/create';

const statesURL = cs.BaseURL + '/address/states';
const districtsURL = cs.BaseURL + '/address/districts';
const wardsURL = cs.BaseURL + '/address/wards';

const styles = theme => ({});

class CreateAgent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            states: [],
            districts: [],
            adr_state_code: '',
            adr_district_code: '',
            wards: [],
            name: '',
            gender: 1,
            birthday: moment()
                .tz('Asia/Ho_Chi_Minh')
                .format('YYYY-MM-DD'),
            phone: '',
            email: '',
            national_id: '',
            adr_state_code: '',
            adr_district_code: '',
            adr_ward_code: '',
            adr_street: '',
        };

        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.loadStates();
    }

    loadStates() {
        let queryString = `${statesURL}`;

        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-hr-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_state: '', adr_state_code: null });
                    this.setState({
                        states: data.data
                    });
                } else {
                    this.setState({
                        states: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({
                    states: []
                });
            });
    }

    loadDistricts() {
        let queryString = `${districtsURL}?state_code=${this.state.adr_state_code}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-hr-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_district: '', adr_district_code: null });
                    this.setState({
                        districts: data.data
                    });
                } else {
                    this.setState({
                        districts: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => { });
    }

    loadWards() {
        let queryString = `${wardsURL}?district_code=${this.state.adr_district_code}`;
        fetch(queryString, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-hr-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    data.data.unshift({ adr_ward: '', adr_ward_code: null });
                    this.setState({
                        wards: data.data
                    });
                } else {
                    this.setState({
                        wards: []
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => { });
    }

    handleDialogAgree = () => {
        if (!this.state.name || !this.state.phone) {
            window.alert('Vui lòng nhập đầy đủ thông tin !');
            return;
        }

        var datainput = {
                code: this.state.code,
                name: this.state.name,
                gender: this.state.gender ? 1 : 0,
                birthday: moment(this.state.birthday).unix(),
                phone: this.state.phone,
                email: this.state.email,
                national_id: this.state.national_id,
                adr_state_code: this.state.adr_state_code,
                adr_district_code: this.state.adr_district_code,
                adr_ward_code: this.state.adr_ward_code,
                adr_street: this.state.adr_street,
                sub_region: this.state.sub_region,
                adr_role_code: this.state.adr_role_code
        };

        console.log(datainput)
        let queryString = `${createAgentURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                code: this.state.code,
                name: this.state.name,
                gender: this.state.gender ? 1 : 0,
                birthday: moment(this.state.birthday).unix(),
                phone: this.state.phone,
                email: this.state.email,
                national_id: this.state.national_id,
                adr_state_code: this.state.adr_state_code,
                adr_district_code: this.state.adr_district_code,
                adr_ward_code: this.state.adr_ward_code,
                adr_street: this.state.adr_street,
                sub_region: this.state.sub_region,
                adr_role_code: this.state.adr_role_code
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-hr-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Đã tạo DSA !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/agent',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => { });
    };

    handleChange = name => event => {
        if (name == 'gender') this.setState({ gender: event.target.checked });
        else
            this.setState(
                {
                    [name]: event.target.value
                },
                () => {
                    if (name == 'adr_state_code') this.loadDistricts();
                    if (name == 'adr_district_code') this.loadWards();
                }
            );
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                    <DialogTitle id="create_agent_form-dialog-title">Tạo DSA</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_agent_code"
                            required={true}
                            value={this.state.code}
                            onChange={this.handleChange('code')}
                            label="Mã nhân sự"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_agent_name"
                            required={true}
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            label="Họ và tên"
                            type="name"
                        />
                        <div fullWidth>
                            <Switch
                                checked={this.state.gender}
                                value={this.state.gender}
                                onChange={this.handleChange('gender')}
                                color="primary"
                            />
                            {this.state.gender ? 'Nam' : 'Nữ'}
                        </div>
                        <TextField
                            fullWidth
                            id="create_agent_birthday"
                            label="Ngày sinh"
                            required={true}
                            value={this.state.birthday}
                            onChange={this.handleChange('birthday')}
                            type="date"
                            defaultValue="2017-05-24"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_agent_phone"
                            required={true}
                            value={this.state.phone}
                            onChange={this.handleChange('phone')}
                            label="Số điện thoại"
                            type="number"
                        />
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_agent_national_id"
                            required={true}
                            value={this.state.national_id}
                            onChange={this.handleChange('national_id')}
                            label="CMND"
                            type="number"
                        />
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_agent_email"
                            value={this.state.email}
                            onChange={this.handleChange('email')}
                            label="email"
                            type="email"
                        />{' '}
                        <FormControl fullWidth>
                            <InputLabel htmlFor="sub_region">Sub Region</InputLabel>
                            <Select
                                value={this.state.sub_region}
                                onChange={this.handleChange('sub_region')}
                                inputProps={{
                                    name: 'SubRegion',
                                    id: 'sub_region'
                                }}
                            >
                                {this.state.states.map(pro => (
                                    <MenuItem value={pro.state_code}>
                                        {pro.state_code && (
                                            <div>
                                                {pro.state_name}
                                            </div>
                                        )}
                                        {!pro.state_code && <div>{pro.state_name}</div>}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="create_adr_role">Phân loại</InputLabel>
                            <Select
                                value={this.state.adr_role_code}
                                onChange={this.handleChange('adr_role_code')}
                                inputProps={{
                                    name: 'role',
                                    id: 'create_adr_role'
                                }}
                            >
                                <MenuItem value={cs.Role_Agent_Order}>Nhân viên thu hồ sơ bản cứng</MenuItem>
                                <MenuItem value={cs.Role_Agent_Card}>Nhân viên trả thẻ</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="create_adr_state">Tỉnh/thành phố (*)</InputLabel>
                            <Select
                                value={this.state.adr_state_code}
                                onChange={this.handleChange('adr_state_code')}
                                inputProps={{
                                    name: 'create_adr_state',
                                    id: 'create_adr_state'
                                }}
                            >
                                {this.state.states.map(pro => (
                                    <MenuItem value={pro.state_code}>{pro.state_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>{' '}
                        <FormControl fullWidth>
                            <InputLabel htmlFor="create_adr_district">Quận / huyện (*)</InputLabel>
                            <Select
                                value={this.state.adr_district_code}
                                onChange={this.handleChange('adr_district_code')}
                                inputProps={{
                                    name: 'create_adr_district',
                                    id: 'create_adr_district'
                                }}
                            >
                                {this.state.districts.map(pro => (
                                    <MenuItem value={pro.district_code}>
                                        {pro.district_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="create_adr_ward">Xã/Phường</InputLabel>
                            <Select
                                value={this.state.adr_ward_code}
                                onChange={this.handleChange('adr_ward_code')}
                                inputProps={{
                                    name: 'adr_ward',
                                    id: 'create_adr_ward'
                                }}
                            >
                                {this.state.wards.map(pro => (
                                    <MenuItem value={pro.ward_code}>{pro.ward_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_adr_street"
                            value={this.state.adr_street}
                            onChange={this.handleChange('adr_street')}
                            label="Đường phố"
                            type="text"
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Tạo Mới
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

CreateAgent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateAgent));
