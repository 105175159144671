import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormData from 'form-data';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import { DropzoneArea } from 'material-ui-dropzone';

import cs from '../../const.js';
import format from 'string-format';
import moment from 'moment-timezone';

const importAgentURL = cs.BaseURL + '/agent/import';
const verifyAgentURL = cs.BaseURL + '/agent/verify_before_import';

function formatErrors(errors) {
    for (var i = 0; i < errors.length; i++) {
        try {
            var row = errors[i];
            if (row.error) row.error = format('Dòng {0} : {1}.', row.rowNo + 1, row.error);

            if (row && row.code == cs.erInvalidRequest.code)
                row.error = format('Dòng {0} : File không hợp lệ.', row.rowNo + 1);
            else if (row && row.code == cs.erProductNotExisted.code)
                row.error = format('Dòng {0} : Mã sản phẩm không tồn tại.', row.rowNo + 1);
            else if (row && row.code == cs.erProductInvalid.code)
                row.error = format('Dòng {0} : Mã sản phẩm không hợp lệ.', row.rowNo + 1);
            else if (row && row.code == cs.erProductIsLocked.code)
                row.error = format('Dòng {0} : Mã sản phẩm đã bị khóa .', row.rowNo + 1);
            else if (row && row.code == cs.erProductCodeCannotEmpty.code)
                row.error = format('Dòng {0} : Mã sản phẩm không được trống.', row.rowNo + 1);
            else if (row && row.code == cs.erPhoneNumberCannotEmpty.code)
                row.error = format('Dòng {0} : Số điện thoại không được trống.', row.rowNo + 1);
            else if (row && row.code == cs.erNameCannotEmpty.code)
                row.error = format('Dòng {0} : Tên không được trống.', row.rowNo + 1);
            else if (row && row.code == cs.erGenderCannotEmpty.code)
                row.error = format('Dòng {0} : Giới tính không được trống.', row.rowNo + 1);
            else if (row && row.code == cs.erStateCannotEmpty.code)
                row.error = format('Dòng {0} : Tỉnh/thành phố không được trống.', row.rowNo + 1);
            else if (row && row.code == cs.erDistrictCannotEmpty.code)
                row.error = format('Dòng {0} : Quận/huyện không được trống.', row.rowNo + 1);
            else if (row && row.code == cs.erInvalidState.code)
                row.error = format('Dòng {0} : Tỉnh/thành phố không hợp lệ.', row.rowNo + 1);
            else if (row && row.code == cs.erInvalidDistrict.code)
                row.error = format('Dòng {0} : Quận/huyện không hợp lệ.', row.rowNo + 1);
            else if (row && row.code == cs.erCannotCreateObject.code)
                row.error = format('Dòng {0} : Không thể tạo Agent.', row.rowNo + 1);
            else if (row && row.code == cs.erCannotUpdateObject.code)
                row.error = format('Dòng {0} : Không thể câp nhật Agent.', row.rowNo + 1);
        } catch (er) {
            if (er) console.log(i + ':error:' + JSON.stringify(er));
        }
    }
    return errors;
}

class ImportAgent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            errors: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false
        };
        this.handleDialogAgree = this.handleDialogAgree.bind(this);
        this.handleDialogDisagree = this.handleDialogDisagree.bind(this);
        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);

        this.handleClose = this.handleClose.bind(this);
    }

    resetState() {
        this.setState({
            file: null,
            errors: [],
            isProcessing: false,
            isFileValid: false,
            isFileImportProcessed: false,
            isFileImported: false
        });
    }

    handleClose = () => {
        this.resetState();
        this.props.onClose();
        this.props.history.push({
            pathname: '/agent',
            state: { reload: true }
        });
    };

    handleDialogAgree = () => {
        this.setState({
            isProcessing: true,
            isFileImportProcessed: false,
            isFileImported: false,
            errors: []
        });

        let queryString = `${importAgentURL}`;

        const formData = new FormData();
        formData.append('excel-file', this.state.file);

        fetch(queryString, {
            method: 'POST',
            body: formData,
            headers: { Authorization: localStorage.getItem(cs.System_Code + '-hr-token') }
        })
            .then(response => response.json())
            .then(data => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                var data = data.data;

                var isFileImported = false;
                if (data && data.requested_qty > 0 && data.failed_qty <= 0) isFileImported = true;

                this.setState({
                    isProcessing: false,
                    isFileImported: isFileImported,
                    errors: formatErrors(data.errors)
                });
                if (isFileImported) {
                    this.props.toastManager.add('Tạo Agent thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });

                    this.handleClose();
                }
            })
            .catch(() => {
                this.resetState();
            });
    };

    handleDialogDisagree = () => {
        this.handleClose();
    };

    handleDropZoneChange(files) {
        if (files && files instanceof Array && files.length > 0) {
            this.setState({
                file: files[0],
                isProcessing: true,
                isFileValid: false,
                isFileImportProcessed: false,
                isFileImported: false,
                errors: []
            });
            var file = files[0];

            let queryString = `${verifyAgentURL}`;

            const formData = new FormData();
            formData.append('excel-file', file);

            fetch(queryString, {
                method: 'POST',
                body: formData,
                headers: { Authorization: localStorage.getItem(cs.System_Code + '-hr-token') }
            })
                .then(response => response.json())
                .then(data => {
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        window.location.reload();
                    }

                    var data = data.data;
                    var isFileValid = false;
                    // alert(JSON.stringify(data));
                    if (
                        data &&
                        (!data.errors ||
                            (data.errors instanceof Array && data.errors.length <= 0)) &&
                        data.requested_qty > 0 &&
                        data.verified_qty > 0 &&
                        data.requested_qty == data.verified_qty
                    )
                        isFileValid = true;

                    this.setState({
                        isProcessing: false,
                        isFileValid: isFileValid,
                        errors: formatErrors(data.errors)
                    });
                })
                .catch(() => {
                    this.resetState();
                });
        } else this.resetState();
    }

    render() {
        const { classes } = this.props;

        if (this.props.open != this.state.open && this.props.open) {
            this.state.open = this.props.open;
        }
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="create_agent_form-dialog-title">
                        Tạo Agent hàng loạt
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Vui lòng liên hệ IT để được hướng dẫn.
                        </DialogContentText>{' '}
                        <Grid
                            container
                            direction="row"
                            justify="fixed-start"
                            alignItems="stretch"
                            spacing={20}
                            className={classes.downloadContainer}
                            gutterBottom
                        >
                            <Grid item xs={7}>
                                <Typography variant="body1">Tải file mẫu :</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <a
                                    download="import-agents-template.zip"
                                    target="_blank"
                                    href="https://media.aidriven.goldengate.biz.vn/images/import_agent_template.zip"
                                    style={{ fontSize: 13, marginTop: 2 }}
                                >
                                    import_agents.zip
                                </a>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="stretch"
                            spacing={1}
                            className={classes.gridContainer}
                        >
                            {this.state.isProcessing && (
                                <div style={{ height: 150 }}>
                                    <CircularProgress
                                        style={{
                                            position: 'relative',
                                            left: '45%',
                                            top: '70px'
                                        }}
                                    />
                                </div>
                            )}
                            {!this.state.isProcessing && (
                                <Grid item gutterBottom>
                                    <DropzoneArea
                                        acceptedFiles={[
                                            'application/vnd.ms-excel',
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                        ]}
                                        dropzoneText={
                                            this.state.file && this.state.file.name
                                                ? this.state.file.name
                                                : 'Upload file exel theo mẫu'
                                        }
                                        maxFileSize={50000000}
                                        filesLimit={1}
                                        showAlerts={false}
                                        showPreviewsInDropzone={false}
                                        onChange={this.handleDropZoneChange}
                                        dropZoneClass={classes.dropZone}
                                    />
                                </Grid>
                            )}
                            <Grid item gutterBottom className={classes.statusItem}>
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileValid && (
                                        <Typography variant="body1">File hợp lệ !</Typography>
                                    )}{' '}
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    !this.state.isFileValid && (
                                        <Typography variant="body1">File không hợp lệ !</Typography>
                                    )}
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileImportProcessed &&
                                    this.state.isFileImported && (
                                        <Typography variant="body1">
                                            File import thành công !
                                        </Typography>
                                    )}
                                {this.state.file &&
                                    !this.state.isProcessing &&
                                    this.state.isFileImportProcessed &&
                                    !this.state.isFileImported && (
                                        <Typography variant="body1">
                                            File import thất bại !
                                        </Typography>
                                    )}
                            </Grid>
                            <Grid item>
                                <div>
                                    <List>
                                        {this.state.errors.map((er, i) => {
                                            return (
                                                <ListItem
                                                    style={{ paddingTop: 5, paddingBottom: 5 }}
                                                >
                                                    <ListItemIcon
                                                        style={{ marginLeft: 0, marginRight: 0 }}
                                                    >
                                                        <Icon
                                                            color="error"
                                                            style={{
                                                                marginLeft: 0,
                                                                marginRight: 0,
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            error_outline
                                                        </Icon>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        classes={{
                                                            primary: { color: 'primary' },
                                                            secondary: { color: 'primary' }
                                                        }}
                                                        secondary={er.error}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {(!this.state.file || !this.state.isProcessing) && (
                            <Button onClick={this.handleDialogDisagree} color="primary">
                                {this.state.isFileImportProcessed ? 'Hoàn tất' : 'Đóng'}
                            </Button>
                        )}
                        {this.state.file &&
                            !this.state.isProcessing &&
                            this.state.isFileValid &&
                            !this.state.isFileImportProcessed &&
                            !this.state.isFileImported && (
                                <Button onClick={this.handleDialogAgree} color="primary">
                                    Tạo Agent
                                </Button>
                            )}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ImportAgent.propTypes = {
    classes: PropTypes.object.isRequired
};
const styles = theme => ({
    gridContainer: {
        paddingTop: 10
    },
    downloadContainer: {
        paddingTop: 10
    },
    dropZone: {
        paddingTop: 10,
        minHeight: 200
    },
    erItem: {
        secondary: 'pink'
    },
    statusItem: {
        marginTop: 5
    }
});
export default withToastManager(withStyles(styles)(ImportAgent));
