import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ToastProvider } from 'react-toast-notifications';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';

import cs from '../const.js';
import Dashboard from './Dashboard';

import AgentList from './agent/AgentList';
import AgentCreationStatistic from './agent/AgentCreationStatistic';

import ChangePassword from './auth/ChangePassword';
import AgentTelesaleList from './telesale/AgentTelesaleList.js';

const revokeTokenURL = cs.BaseURL + '/auth/logout';

const drawerWidth = 240;

const styles = (theme) => ({
    root: {
        display: 'flex',
        marginTop: 0,
        paddingTop: 10
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: 'rgb(68, 126, 236)'
    },
    toolbarHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 2px',
        ...theme.mixins.toolbar
    },
    username: {
        marginRight: 1,
        textAlign: 'right',
        fontWeight: 550,
        fontSize: 11
    },
    avatar: {
        marginRight: 20
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        paddingTop: 0,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        height: '100vh',
        overflow: 'auto'
    },
    chartContainer: {
        marginLeft: -22
    },
    tableContainer: {
        height: 320
    },
    h5: {
        marginBottom: theme.spacing.unit * 2
    }
});

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            showCreateOrder: false,
            showChangePassword: false
        };
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    handleLogOut = () => {
        let queryString = `${revokeTokenURL}`;
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-hr-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            })
            .catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            });

        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    };

    isSysHR = () => {
        var user = localStorage.getItem(cs.System_Code + '-hr-user');
        if (user) return JSON.parse(String(user)).name == 'syshr';
    };

    handleShowChangePassword = () => {
        this.setState({ showChangePassword: true });
    };

    handleCloseChangePassword = () => {
        this.setState({ showChangePassword: false });
    };

    render() {
        const { classes } = this.props;

        var username = '';
        var user = localStorage.getItem(cs.System_Code + '-hr-user');
        if (user) username = JSON.parse(String(user)).name;

        const mainListItems = (
            <div>
                <ListItem button component={Link} to="/agent">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            supervised_user_circle
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Nhân viên (DSA)" />
                </ListItem>

                <ListItem button component={Link} to="/telesale">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            supervised_user_circle
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Nhân viên Telesale" />
                </ListItem>

                {/* <ListItem button component={Link} to="/agent_creation_statistic">
                    <ListItemIcon>
                        <Icon color="disable" style={{ marginLeft: 6, fontSize: 26 }}>
                            supervised_user_circle
                        </Icon>
                    </ListItemIcon>

                    <ListItemText primary="Thống kê DSA" />
                </ListItem> */}
            </div>
        );

        const AgentArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Quản lý Nhân Viên Kinh Doanh
                </Typography>
                <div className={classes.tableContainer}>
                    <AgentList />
                </div>
            </div>
        );

        const TelesaleArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Quản lý Nhân Viên Telesale
                </Typography>
                <div className={classes.tableContainer}>
                    <AgentTelesaleList />
                </div>
            </div>
        );

        const AgentCreationStatisticArea = () => (
            <div>
                <Typography variant="h5" gutterBottom component="h2">
                    Thống kê Nhân Viên Kinh Doanh
                </Typography>
                <div className={classes.tableContainer}>
                    <AgentCreationStatistic />
                </div>
            </div>
        );

        return (
            <Router>
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="absolute"
                        className={classNames(
                            classes.appBar,
                            this.state.open && classes.appBarShift
                        )}
                    >
                        <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(
                                    classes.menuButton,
                                    this.state.open && classes.menuButtonHidden
                                )}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                variant="h5"
                                color="inherit"
                                noWrap
                                className={classes.title}
                            >
                                Quản trị Nhân Sự
                            </Typography>

                            <Button
                                color="inherit"
                                iconStyle={{
                                    height: 200,
                                    width: 200,
                                    fontSize: '48px'
                                }}
                                aria-label="Đổi mật khẩu"
                                onClick={this.handleShowChangePassword}
                            >
                                <Icon>vpn_key</Icon>
                                <span style={{ marginLeft: 10 }}>Đổi mật khẩu</span>
                            </Button>

                            <Button
                                color="inherit"
                                iconStyle={{
                                    height: 200,
                                    width: 200,
                                    fontSize: '48px'
                                }}
                                aria-label="Đăng Xuất"
                                onClick={this.handleLogOut}
                            >
                                <Icon>exit_to_app</Icon>
                                <span style={{ marginLeft: 10 }}>Đăng Xuất</span>
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(
                                classes.drawerPaper,
                                !this.state.open && classes.drawerPaperClose
                            )
                        }}
                        open={this.state.open}
                    >
                        <div className={classes.toolbarHeader}>
                            {cs.System_Id === 1 ? (
                                <Avatar
                                    className={classes.avatar}
                                    src={require('../img/logo-app-trans.png')}
                                />
                            ) : (
                                <Avatar
                                    className={classes.avatar}
                                    src={require('../img/logo-small.png')}
                                />
                            )}
                            <Typography variant="body" className={classes.username}>
                                {username}
                            </Typography>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List>{mainListItems}</List>
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Route exact path="/" component={AgentArea} />
                        <Route path="/agent" component={AgentArea} />
                        <Route path="/telesale" component={TelesaleArea} />
                        <Route
                            path="/agent_creation_statistic"
                            component={AgentCreationStatisticArea}
                        />

                        <ToastProvider autoDismissTimeout={3000} placement="bottom-center">
                            <ChangePassword
                                open={this.state.showChangePassword}
                                onClose={this.handleCloseChangePassword}
                            />
                        </ToastProvider>
                    </main>
                </div>
            </Router>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
